@media only screen and (max-width: 600px) {
    .navbar-mobile {
        height: 100px;
        position: fixed;
        width: 100vw;
        top: 0px;
        z-index: 20;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        transition: all .5s;

    }

    .drop-down {
        height: 200px;
        background-color: var(--darkBlue);
    }

    .navbar-mobile .menu-button {
        margin: 0px;
        position: absolute;
        font-size: 3rem;
        height: 60px;
        width: 60px;
        background: none;
        border: none;
        color: var(--lightGray);
        top: 0px;
        transition: all 1s;
        /* background-color: antiquewhite; */
        padding: 0;
    }

    .drop-down .menu-button {
        transform: rotate(135deg)
    }

    .navbar-option-mobile {
        background: none;
        border: none;
        font-family: var(--Font1);
        font-size: 1.8rem;
        color: var(--lightGray);
    }

    .navbar-option-container-mobile.show {
        height: 50%;
        align-self: flex-end;
        display: flex;
        width: 100%;
        flex-direction: column;
        align-items: flex-end;
        gap: 10px;
        overflow: hidden;
        padding-bottom: 10px;
        opacity: 1;
    }

    .navbar-option-container-mobile {
        display: none;
        opacity: 0;
        transition: all 1s;

    }
}

@media only screen and (min-width: 600px) {
    .navbar-mobile {
        display: none;
    }
}