.DecorationContainer {
    position: fixed;
    top: 75px;
    z-index: -10;
}

.Circle {
    background-color: aliceblue;
    content: 'hi';
    border-radius: 10px;

}