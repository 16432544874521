@keyframes slideIn {
    0% {
        opacity: 0;
        transform: translateX(-100%);
    }

    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

.HomePageContainer {
    position: relative;
    animation: fadeIn 3s;
    padding-left: 5vw;
    padding-right: 5vw;
    display: flex;
    justify-content: center;
    padding: 5vh;
}


#HomePagePicture {
    width: 400px;
    height: 530px;
    border-radius: 10px;
}

.ProjectsButton {
    font-family: var(--Font1);
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    background: none;
    color: var(--lightGray);
    transition: all .5s;
}

.ProjectsButton:hover {
    scale: 1.05;
    color: var(--gray);
}

.HomePageInfoContainer {
    position: fixed;
    max-width: 1200px;
    height: fit-content;

}

.HomePageInfoContainer h3 {
    font-family: var(--Font2);

}

.AboutMeInfo {
    width: 50%;
}

.BoxLine {
    position: relative;
    border: 5px solid var(--white);
    top: 0px;
    left: 0px;
    width: 400px;
    height: 532px;
    z-index: -1;
}

.LanguageLogos {
    height: 40px;
    width: 40px;
    margin-right: 10px;
    border-radius: 5px;
    transition: all 1s;
}

.card {
    width: 400px;
    height: 530px;
    perspective: 1000px;
    display: flex;
}

.card-inner {
    width: 100%;
    height: 100%;
    transform-style: preserve-3d;
    transition: transform 0.5s;
}

.card-flipped {
    transform: rotateY(180deg);
}

.card-front,
.card-back {
    background-color: var(--white);
    width: 100%;
    height: 100%;
    position: absolute;
    backface-visibility: hidden;
    border-radius: 5px;
}

.card-back p {
    padding: 20px 20px 0 20px;
    color: black;
    margin: 0;
    font-size: .956rem;

}

.card-back p b {
    font-family: var(--Font2);
}

.card-front {
    transform: rotateY(0deg);
}

.card-back {
    transform: rotateY(180deg);
}




@media only screen and (max-width: 600px) {

    .LanguageLogos {
        height: 25px;
        width: 25px;
    }

    .AboutMeInfo h3 {
        font-size: 1rem;
    }

    .HomePageContainer {
        margin-top: 0;
        position: relative;
        flex-direction: column;
        justify-content: center;
        padding: 20px;
    }

    .HomePageInfoContainer {
        margin-top: 10px;
        padding-bottom: 10vh;
        width: 100%;
        flex-direction: column;
        position: relative;
    }

    .AboutMeInfo {
        width: 400px;
        margin-bottom: 20px;
    }

    #HomePagePicture {
        width: 400px;
        height: auto;
    }

    .AboutMeInfo {
        width: 90%;
    }

    #HomePagePicture {
        width: 100%;

    }
}