@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.LandingPageContainer {
    position: absolute;
    width: 100vw;
    height: 100vh;
    background-color: var(--darkestBlue);
    z-index: 50;
}

.NameDisplay {
    line-height: 5rem;
}

.FirstName {
    opacity: 0;
    animation: fadeIn 2s;
    animation-delay: 0s;
}

.LastName {
    opacity: 0;
    animation: fadeIn 2s;
    animation-delay: .66s;
}

.Portfolio {
    opacity: 0;
    animation: fadeIn 2s;
    animation-delay: 1.33s;
}

@media only screen and (max-width: 900px) {
    .NameDisplay {
        line-height: 3.5rem;
    }
}