@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}


.photography-portfolio {
    display: flex;
    /* justify-content: center; */
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;
    height: calc(100vh - 75px - 40px);
    padding: 20px;
}

.photography-portfolio .photography-summary {
    width: 20%;
    left: 20px;
    color: var(--white);

}



.photography-portfolio .container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
    max-width: 1580px;
    width: 100%;
    overflow-x: scroll;

}

.photography-portfolio img {
    object-fit: cover;
    opacity: 1;
    height: 50vh;
    animation: fadeIn 3s;
    background-color: var(--darkBlue);
    cursor: pointer;
}

.photography-portfolio img:hover {
    opacity: .8;
}

.photography-portfolio img .selected {
    scale: 2;
}

.high-lighted-image-container {
    position: absolute;
    background-color: #0a1a27db;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;

}

.high-lighted-image-container img {
    height: calc(100vh - 75px);
    top: 75px;
    animation: none;

}

.high-lighted-image-container img:hover {
    opacity: 1;
}

@media only screen and (max-width: 600px) {
    .photography-portfolio {
        flex-direction: column;
        height: fit-content;
        gap: 20px;
        background-color: var(--darkestBlue);
        margin-top: 75px;
    }

    .photography-portfolio .photography-summary {
        width: 100%;
    }

    .photography-portfolio .photography-summary h3 {
        font-size: 1.5rem;
    }

    .photography-portfolio img {
        height: auto;
        width: 100%;
        margin-top: 10px;
        pointer-events: none;
    }
}