.NavBarContainer {
    height: 75px;
    padding-left: 5vw;
    padding-right: 5vw;
    animation: fadeIn 3s;
    font-family: var(--Font1);
    font-size: 1.2rem;
    background-color: var(--darkestBlue);
    position: sticky;
    top: 0px;
    z-index: 20;
    justify-content: space-between;
}

.NavBarLink {
    text-decoration: none;
    color: var(--lightGray);
    font-size: 1.5rem;
    transition: all .5s;
}

.NavBarLink:hover {
    color: var(--gray);
    scale: 1.05;
}

@media only screen and (max-width: 600px) {
    .NavBarContainer {
        display: none;
    }
}